import { render, staticRenderFns } from "./typeForm.vue?vue&type=template&id=563943c4&scoped=true&"
import script from "./typeForm.vue?vue&type=script&lang=js&"
export * from "./typeForm.vue?vue&type=script&lang=js&"
import style0 from "./typeForm.vue?vue&type=style&index=0&id=563943c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563943c4",
  null
  
)

export default component.exports